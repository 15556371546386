<template>
  <div class="Agent_tj">
    <div class="cards">
      <el-card v-for="(item, index) in shouyilist" :key="index">
        <div class="title">{{ item.name }}</div>
        <div class="content">
          <div v-show="index != 2" class="detaild">
            <div class="sy">{{ item.sy | digit }}</div>
            <div class="contrast">
              <div>
                <span>周同比</span>
                <i
                  :class="[
                    'icons',
                    item.ztb_status == 0
                      ? 'el-icon-caret-bottom'
                      : 'el-icon-caret-top',
                  ]"
                  :style="item.ztb_status == 0 ? 'color:red' : 'color:green'"
                ></i>
                <span>{{ item.ztb }}</span>
              </div>
              <div>
                <span>日同比</span>
                <i
                  :class="[
                    'icons',
                    item.rtb_status == 0
                      ? 'el-icon-caret-bottom'
                      : 'el-icon-caret-top',
                  ]"
                  :style="item.rtb_status == 0 ? 'color:red' : 'color:green'"
                ></i>
                <span>{{ item.rtb }}</span>
              </div>
            </div>
          </div>
          <div v-show="index == 2" style="font-size: 30px; line-height: 70px">
            {{ item.sy | digits }}
          </div>
        </div>
        <div class="foot">
          <el-divider></el-divider>
          <div v-show="index == 0">日业绩 : {{ item.day | digit }}</div>
          <div v-show="index == 1">日提现 : {{ item.day | digit }}</div>
          <div v-show="index == 2">日新增 : {{ item.day | digits }}</div>
        </div>
      </el-card>
    </div>

    <div class="body">
      <el-card>
        <div class="title">
          <span>注册</span>
          <div>
            <el-button
              :type="active == index ? 'warning' : 'default'"
              size="mini"
              v-for="(item, index) in tabs"
              :key="index"
              @click="choose_date(index)"
            >
              {{ item }}
            </el-button>
            <el-popover
              placement="bottom"
              width="300"
              v-model="visible"
              trigger="click"
            >
              <el-date-picker
                v-model="month"
                size="mini"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
              >
              </el-date-picker>
              <el-button type="primary" size="mini" @click="screen"
                >确认</el-button
              >
              <el-button
                slot="reference"
                size="mini"
                :type="active == 5 ? 'warning' : 'default'"
                >筛选</el-button
              >
            </el-popover>
          </div>
        </div>
        <div class="eharts">
          <div class="treeview" ref="tree" id="tree"></div>
          <div class="rank">
            <div class="title">渠道推广员邀请排行榜</div>
            <div class="content" @click="goDetail()">
              <div class="items" v-for="(item, index) in rank" :key="index">
                <div class="name">
                  <div :class="['ranks', index < 3 ? 'sign' : '']">
                    {{ index + 1 }}
                  </div>
                  <div>{{ item.name }}</div>
                </div>
                <div class="count">{{ item.count }}人</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
let that;
export default {
  data() {
    return {
      myChart: null,
      shouyilist: [],
      tabs: ["今日", "昨日", "本周", "本月", "本年"],
      rank: [],
      active: 0,
      ehcarts_: {
        date: "2022-10-01", //日期
        //x轴
        xaxis: [],
        //x轴数据1
        xdata: [],
        max: 200, //x轴数据12最大值
        end: 22, //展示多少数据，百分比
      },
      month: "",
      visible: false,
    };
  },
  created() {
    that = this;
    if (!this.$store.state.userInfo) {
      // 防止未登录
      this.$router.push({
        path: "/login",
      });
    } else {
      this.change();
    }
  },
  mounted() {
    this.choose_date(0);
  },
  methods: {
    screen() {
      this.visible = false;
      this.active = 5;
      var url = 'statistic/timeTjData';
			let params = {
				time_type: this.active+1,
				agent_type: 1,
				month: this.month
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.ehcarts_ = res.data_list;
					that.rank = res.list;
					that.init_echarts();
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
    },
    choose_date(i) {
      this.active = i;
      var url = "statistic/timeTjData";
      let params = {
        time_type: i + 1,
        agent_type: 1,
      };
      this.fd_post(url, params)
        .then((res) => {
          if (res.status) {
            that.ehcarts_ = res.data_list;
            that.rank = res.list;
            that.init_echarts();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch((err) => {
          that.$message.error("网络错误");
        });
    },
    change() {
      var url = "statistic/agentTjData";
      let params = {};
      this.fd_post(url, params)
        .then((res) => {
          if (res.status) {
            console.log(res.detail);
            that.shouyilist = res.detail;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch((err) => {
          that.$message.error("网络错误");
        });
    },
    beforeDestroy() {
      window.removeEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    init_echarts() {
      let echarts_ = this.ehcarts_;
      this.myChart = this.$echarts.init(this.$refs.tree);
      this.myChart.setOption({
        color: ["#FFCF4A"], //柱状图颜色
        tooltip: {
          trigger: "axis", //鼠标悬浮交互时的信息提示，item只显示该点的信息，axis显示该列坐标轴对应的数据
        },
        title: {
          show: true,
          subtext: `${echarts_.date}`, //副标题
          x: 5,
          y: 5,
          subtextStyle: {
            fontSize: 16,
          },
        },
        grid: {
          //图表距离容器的距离
          left: 40,
          top: 80,
          right: 40,
          bottom: 30,
        },
        legend: {
          //图形解释部分
          data: ["注册人数"], //要和series的name对应
          top: 15,
          right: 15,
          width: 100,
          textStyle: {
            width: 100,
          },
        },
        xAxis: [
          //x坐标轴
          {
            type: "category", //坐标轴类型，value数值轴，category类目轴，time时间轴，log对数轴
            //data只在类目轴中有效
            data: echarts_.xaxis,
            axisPointer: {
              //坐标轴指示器
              type: "shadow", //指示器类型,line直线,shadow阴影,none无
            },
          },
        ],
        yAxis: [
          //y坐标轴
          {
            type: "value", //同x轴
            min: 0,
            max: echarts_.max,
            interval: echarts_.interval, //间隔
          },
        ],
        dataZoom: [
          {
            type: "slider", //滑块活动，设置为slider能在坐标系内拖动
            show: true, //是否显示
            bottom: -20,
            start: 0, //滚动条长度，百分比
            end: echarts_.end,
            backgroundColor: "rgba(68,87,74,1)",
          },
        ],
        series: [
          {
            name: "注册人数",
            type: "bar",
            data: echarts_.xdata,
          },
        ],
      });
      //宽度自适应
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    goDetail() {
      this.$router.push("/agent_tj/agent_tj_detail");
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./Agent_tj.scss";
</style>